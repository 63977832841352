import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp-ecom-oxid-next/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp-ecom-oxid-next/src/components/ErrorBoundary/ErrorBoundary.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp-ecom-oxid-next/src/components/GlobalThisExtensions.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp-ecom-oxid-next/src/components/LoadingScreen.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp-ecom-oxid-next/src/modals/ModalsProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp-ecom-oxid-next/src/providers/StateProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/webapp-ecom-oxid-next/src/theme.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.5.1_@mantine+hooks@7.5.1_@types+react@18.2.52_react-dom@18.2.0_react@18.2.0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.5.1_@mantine+hooks@7.5.1_@types+react@18.2.52_react-dom@18.2.0_react@18.2.0/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+notifications@7.5.1_@mantine+core@7.5.1_@mantine+hooks@7.5.1_react-dom@18.2.0_react@18.2.0/node_modules/@mantine/notifications/esm/Notifications.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+notifications@7.5.1_@mantine+core@7.5.1_@mantine+hooks@7.5.1_react-dom@18.2.0_react@18.2.0/node_modules/@mantine/notifications/esm/notifications.store.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+nprogress@7.5.1_@mantine+core@7.5.1_@mantine+hooks@7.5.1_react-dom@18.2.0_react@18.2.0/node_modules/@mantine/nprogress/esm/NavigationProgress.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+nprogress@7.5.1_@mantine+core@7.5.1_@mantine+hooks@7.5.1_react-dom@18.2.0_react@18.2.0/node_modules/@mantine/nprogress/esm/nprogress.store.mjs")